import React from 'react';
import config from '../../config';

import Bowser from "bowser";

export default class Nav extends React.Component{ 
  constructor(props){
    super(props);
    
    this.state = {
      name:'',
      email:'',
      phone:'',
      message:'',
      loc:'',
      userAgent: '',
      isSubmitted: false,
      disableSubmit: true
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.postFormData = this.postFormData.bind(this);
  }
  
  componentDidMount() {
    this.setState({...this.state, loc: document.location,
      userAgent: window.navigator.userAgent});
    
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validEmailRegex = 
      RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    
    var disableSubmit = this.state.disableSubmit;
    if (name === 'email') {
      disableSubmit = ((validEmailRegex.test(value)) && (this.state.name.trim().length >= 3)) 
        ? false : true;
    } else if (name === 'name') {
      disableSubmit = ((validEmailRegex.test(this.state.email)) && (value.trim().length >= 3)) 
        ? false : true;
    }
    
    this.setState({...this.state,
      [name]: value, disableSubmit: disableSubmit
    });
    
  }
  render() {
    var kThis = this;
    const msgMain = !this.state.isSubmitted 
      ? <>
            <p style={{fontSize:'75%', margin:'.5em'}}>The best way to begin a conversation with us is by sending a message. 
              The info that you provide, will help us to have the most appropriate person contact you. 
              Thanks!
            </p>
            <form method="post" onSubmit={this.postFormData}>
              <div className="row gtr-uniform" >
                <div className="col-12" >
                  <input type="text" name="name" id="name" placeholder="Name" 
                    value = {this.state.name}   onChange={this.handleInputChange}/>
                </div>
                <div className="col-12" style={{paddingTop:'1em'}}>
                  <input type="email" name="email" id="email" placeholder="Email" 
                    value = {this.state.email}   onChange={this.handleInputChange}/>
                </div>
                <div className="col-12" style={{paddingTop:'1em'}}>
                  <input type="text" name="phone" id="phone" placeholder="Phone" 
                    value = {this.state.phone}   onChange={this.handleInputChange}/>
                </div>
                <div className="col-12" style={{paddingTop:'1em'}}>
                  <textarea name="message" id="message" placeholder="Enter your message" rows="3"
                     onChange={this.handleInputChange} value={this.state.message} />
                </div>
                <div className="col-12" style={{display:'flex',flexDirection:'row-reverse', paddingTop:'1em'}}>
                  <ul className="actions">
                    <li><input type="submit" value="Send " className="primary" disabled={this.state.disableSubmit}/>
                        
                    </li>
                    
                  </ul>
                </div>
              
              </div>
            </form>
        </>
        : <h2>
            Thank you for reaching out to us. We will get back to you as soon as we can.
          </h2>;
    return (
      <nav id="menu" style={{right:0, top:0}}>
        <div style={{display:'flex',flexDirection:'row-reverse', marginRight:'1em'}}>
            <a href='#' className={`icon style1 fa-close`} style={{fontSize:'x-large'}}
              onClick={e => {
                e.preventDefault();
                kThis.props.onClose();
              }}>
              <span className="label">x</span>
            </a>
        </div>
          
           
        <div className="inner" style={{position:'relative', padding:'1em'}}>
          <h3 style={{marginBottom:'.5em'}}>Reach Us</h3>
            {msgMain}
            <ul className="icons">
              <h4 style={{margin:'0 1em .25em 0'}}>Follow us</h4>
              {config.socialLinks.map(social => {
                const { icon, name, url } = social;
                
                return (
                  <li key={url} style={{marginRight:'.25em'}}>
                    <a href={url} className={`icon style2 ${icon}`} 
                      style={{border:'solid 1px #c9c9c9', width:'3.5em', paddingLeft:'1em'}} target="_blank" rel="noreferrer">
                      <span className="label">{name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
  
            
        </div>
        
      </nav>
    );
  }

  postFormData() {
    var kThis = this;
    const axios = require('axios');
    const browser = Bowser.getParser(this.state.userAgent);
    const userdata = browser.getResult();
    const loc = this.state.loc;
    //const postUri = loc.protocol+'//'+loc.hostname+'/d/contact';
    const postUri = loc.protocol+'//'+loc.hostname+':3001/contact';
    axios({
      method: 'post',
      url: postUri,
      data: {
        'name': this.state.name,
        'email': this.state.email,
        'phone': this.state.phone,
        'message': this.state.message,
        'userdata': JSON.stringify(userdata)
      }
    }).then((res) => {
      this.setState({...this.state, isSubmitted: true});
      console.log(this.state);
      //kThis.props.onClose(false);
    }, (error) => {
      kThis.props.onClose(false);
    });
  }
  
}
