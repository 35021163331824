import React from 'react';
import { Link } from 'gatsby';
const URL = require('url').Url;
const linkColor = '#080880';
export  function Card({ className, cardTitle, cardImage, cardLink, children, height }) {
    const displayImage =  (typeof cardImage !== 'undefined') 
                    ? <span className="image left" >
                        <img src={cardImage} alt=""
                            style={{width:'55px', marginLeft:'5px'}}/>
                    </span>
                    : null;
    const displayTitle = (typeof cardTitle !== 'undefined') 
                    ? <p style={{fontWeight:400, margin:'0 0 .15em 0'}}>{cardTitle}</p> : null;
    
    className = (typeof className === 'undefined')
        ? '' : className;
    height = (typeof height !== 'undefined') ? height : '125px';

    return (
        
            <article className={`col-4 col-6-medium col-12-small ${className}`}
                style={{
                boxShadow: '0 2px 2px rgba(0,0,0,.3)',
                height: height,
                borderColor: '#222',
                padding: '4px', margin:'10px 0 0 0',
                overflow:'hidden',
                display:'flex', flexDirection:'row'
                }}
            >
                {displayImage}
                <div>
                    
                        {displayTitle}
                    
                    <div style={{fontSize:'75%', margin:'0 0 5px 0', }}>
                        {children}
                    </div>
                </div>
            </article>
        
    );
    
}

export class rootCard extends React.Component{ 
    constructor(props){
        super(props);
        
        this.state = {
          isExternalHost: false,
        }
        this.buildCardTitleMore = this.buildCardTitleMore.bind(this);
      }
      
      componentDidMount() {
        const currentHost = window.document.location.host;
        const cardLinkHost = new URL(this.props.cardLink);
        this.setState({...this.state, 
            isExternalHost: (currentHost !== cardLinkHost.host) ? true : false});
      }

      buildCardTitleMore (cardTitle, cardLink=null, cardImage=null) {
          
        var displayTitle, displayMore, displayImage;
        const imgOpacity = .8;
        
        if (cardLink === null) {
            displayImage = <span className="image right" >
                                <img src={cardImage} alt="" style={{opacity:imgOpacity}}/>
                            </span>;
            displayTitle = <p style={{margin:0, fontWeight:400}}>{cardTitle}</p>;
            displayMore = null;
                           
        } else {
            const cardLinkHost = new URL(cardLink);
            
            if ((this.state.isExternalHost) && (cardLinkHost.host !== null)) {
                if (typeof cardTitle !== 'undefined') {
                    displayTitle = <a href={cardLink} style={{color:linkColor, borderStyle:'none' }} 
                            rel="noreferrer" target="_blank">
                            <p style={{margin:0, fontWeight:400}}>{cardTitle}</p>
                        </a>;
                    displayMore = <ul className="icons"><li >
                                    <a href={cardLink} style={{borderStyle:'none', marginRight:'.5em'}}
                                        rel="noreferrer" className={`icon style1 fa-long-arrow-right`} target="_blank">
                                        <span className="label">More</span>
                                    </a>
                                </li></ul>;
                } else displayTitle = displayMore = null;
                if (cardImage !== null) {
                    displayImage = <span className="image right" >
                        <a href={cardLink} style={{borderStyle:'none'}}
                            rel="noreferrer" className={`image right`} target="_blank">
                            <img src={cardImage} alt="" style={{opacity:imgOpacity}}/>
                        </a>
                    </span>;
                } else displayImage = null;        
            } else {
                
                if (typeof cardTitle !== 'undefined') {
                    displayTitle = <Link to={cardLink} 
                                        style={{borderStyle:'none', color:linkColor, fontWeight:400}}>
                                        {cardTitle}
                                </Link>;
                    displayMore = <ul className="icons" >
                        <li >
                            <Link to={cardLink} className={'icon style1 fa-long-arrow-right'} style={{margin:0}}>
                                <span className='label'>More</span>
                            </Link>
                        </li></ul>;
                } else displayTitle = displayMore = null;
                if ((cardImage !== null) && (typeof cardImage !== 'undefined')) {
                    displayImage = <span className="image right" >
                        <Link to={cardLink} style={{borderStyle:'none'}} >
                            <img src={cardImage} alt="" style={{opacity:imgOpacity}}/>
                        </Link>
                    </span>;
                } else displayImage = null;   
            }
        }
        
        return {'title':displayTitle, 'more':displayMore, 'image':displayImage};
    }
}

/* function isExternalHost (cardLink) {
    const currentHost = window.document.location.host;
    const cardLinkHost = new URL(cardLink);
    return (currentHost !== cardLinkHost.host) ? true : false;
} */

export class HomeCard extends rootCard{ 
    constructor(props){
      super(props);
      this.state = { props: props}
      this.HomeCard = this.HomeCard.bind(this);
    }

    render() {
        const props = this.state.props;
        
        if (typeof props !== 'undefined') {
            
            return this.HomeCard(props.className, props.cardTitle,
                props.cardImage, props.cardLink, props.children, props.height);
        } else return null;
            
    }
    HomeCard( className, cardTitle, cardImage, cardLink, children, height ) {
        
        height = (typeof height !== 'undefined') ? height : '125';
        
        className = (typeof className === 'undefined')
            ? '' : className;
        
        
        const linkTag = this.buildCardTitleMore(cardTitle, cardLink, cardImage);
        
        return (
            
                <article className={`col-4 col-6-medium col-12-small ${className}`}
                    style={{
                        margin:'10px 0 0 0',
                        overflow:'hidden', fontSize:'1em', paddingLeft:'.25em'
                    }}
                >
                    <div style={{borderColor:'#222', boxShadow: '1px 1px 1px rgba(0,0,0,.2)', 
                        padding:'.5em', margin:'.25em', backgroundColor:'#eeeeeedd',
                        borderRadius:'.25em', display:'flex', flexDirection:'column'}}>
                        <div >
                            {linkTag.title}
                        </div>
                        
                        <div >
                            {linkTag.image}    
                            
                        </div>
                        <div style={{height:'3em', fontSize:'90%', fontWeight:'400'}}>
                                {children}
                        </div>
                        <div style={{height:'1.5em', alignSelf:'flex-end'}}>
                            {linkTag.more}
                        </div>
                    </div>
                </article>
            
        );
    }
}

export  function MiniCard({ className, cardTitle, cardImage}) {
    const displayImage =  (typeof cardImage !== 'undefined') 
                    ? <span className="image left">
                        <img src={cardImage} alt=''
                            style={{width:'40px'}}/>
                    </span>
                    : null;
    const displayTitle = (typeof cardTitle !== 'undefined') 
                    ? <p style={{fontWeight:400, margin:'0 0 1em 0'}}>{cardTitle}</p> : null;
    

    return (
        
            <article className={`col-4 col-6-medium col-12-small ${className}`}
                style={{
                boxShadow: '0 1px 2px rgba(0,0,0,.3)',
                height: '75px',
                borderColor: '#222',
                padding: '4px', margin:'10px 0 0 0',
                overflow:'hidden'
                }}
            >
                {displayImage}
                {displayTitle}
                
            </article>
        
    );
    
}

export class MicroCard extends rootCard{ 
    constructor(props){
      super(props);
      
      this.MicroCard = this.MicroCard.bind(this);
    }

    render() {
        return this.MicroCard(this.props.className, this.props.cardTitle,
            this.props.cardImage, this.props.cardLink);
    }
    
    MicroCard( className, cardTitle, cardImage, cardLink, style) {
        const displayImage =  (typeof cardImage !== 'undefined') 
                    ? <span className="image left">
                        <img src={cardImage} alt='' style={{width:'30px'}}/>
                    </span>
                    : null;
    
    
        const linkTag = this.buildCardTitleMore(cardTitle, cardLink);

        const cardContent = <>
                        {displayImage}
                        {linkTag.title}
                    </>;
    

        return (
        
            <article className={` ${className}`}
                style={{
                boxShadow: '0 1px rgba(0,0,0,.1)',
                height: '40px', fontSize:'.8em',
                borderColor: '#222',
                padding: '4px', margin:'0px',
                overflow:'hidden'
                }}
            >
                {cardContent}
                
            </article>
        
        );
    
    }
}

export class TabTitleCard extends rootCard{ 
    constructor(props){
      super(props);
      
      this.TabTitleCard = this.TabTitleCard.bind(this);
    }

    render() {
        return this.TabTitleCard(this.props.className, this.props.cardTitle,
            this.props.cardImage, this.props.cardLink, this.props.selected);
    }
    
    TabTitleCard( className, cardTitle, cardImage, cardLink, selected) {
        const displayImage =  (typeof cardImage !== 'undefined') 
                    ? <span className="image left">
                        <img src={cardImage} alt='' style={{width:'30px'}}/>
                    </span>
                    : null;
    
    
        const linkTag = this.buildCardTitleMore(cardTitle, cardLink);
        const bgColor = selected ? '#1c2841' : '#fff';
        const titleColor = selected ? '#fff' : '#1c2841';

        const cardContent = <>
                        {displayImage}
                        {linkTag.title}
                    </>;
    
        
        return (
        
            <article className={` ${className}`}
                style={{
                boxShadow: '0 1px rgba(0,0,0,.1)',
                height: '60px', fontSize:'1em',
                backgroundColor: bgColor,
                borderColor: '#222',
                borderRadius: '0px 0px .5em .5em',
                color: titleColor,
                padding: '10px', margin:'0px',
                overflow:'hidden'
                }}
            >
                {cardContent}
                
            </article>
        
        );
    
    }
}

export class ImageCard extends rootCard{ 
    constructor(props){
      super(props);
      
      this.ImageCard = this.ImageCard.bind(this);
    }

    render() {
        return this.ImageCard(this.props.className, this.props.cardTitle,
            this.props.cardImage, this.props.cardLink, this.props.width, this.props.height, this.props.border);
    }
    ImageCard( className, cardTitle, cardImage, cardLink=null, width, height, border=true) {

        var imageTag;
        
        if (typeof cardImage !== 'undefined') {
            if (typeof width === 'undefined') {
                if (typeof height === 'undefined') {
                    imageTag = <span className="image img">
                        <img src={cardImage} alt=''
                            style={{height:'35px', objectFit:'scale-down', textDecoration:'none'}}/>
                    </span>;
                } else {
                    imageTag = <span className="image img" >
                        <img src={cardImage} alt=''
                            style={{height:height, objectFit:'scale-down'}}/>
                    </span>;
                }
            } else {
                imageTag = <span className="image img" >
                    <img src={cardImage} alt=''
                        style={{width:width, objectFit:'scale-down'}}/>
                </span>;
            }
        } else imageTag = null;
                        
        var cardContent = imageTag;
        
        cardContent = (cardLink !== null)
                        ? (this.state.isExternalHost
                            ?   <a href={cardLink} style={{borderStyle:'none'}}
                                    rel="noreferrer"  target="_blank">
                                    {imageTag}
                                </a>
                            : <Link to={cardLink} style={{borderStyle:'none'}}>
                                    {imageTag} 
                                </Link>)
                        :  imageTag;
        
                    
        if (border) {
            return (
            
                <article className={`col-4 col-6-medium col-12-small ${className}`}
                    style={{
                        boxShadow: '0 1px rgba(0,0,0,.1)',
                        borderColor: '#222',
                        padding: '4px', margin:'0px',
                        overflow:'visible'
                    }}
                >
                    {cardContent}
                    
                </article>
            
            );
        } else {
            return (
            
                <article className={`col-4 col-6-medium col-12-small ${className}`}
                    style={{
                        
                        padding: '4px', margin:'2px',
                        overflow:'hidden'
                    }}
                >
                    {cardContent}
                    
                </article>
            
            );
        }
    }
    
    
}