module.exports = {
  siteTitle: 'Cloutics', // <title>
  manifestName: 'Cloutics',
  manifestShortName: 'Cloutics Home', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/cloutics-logo.svg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Cloutics',
  subHeading: 'Accelerate Innovation, Supplement Tech Talent & Transform Business Process.',
  mailerPort: 3001,
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/cloutics/',
    }
  ],
  joblist: {
    url: "https://cloutics.sharepoint.com/sites/hr/_api/web/lists/getByTitle('VisaJobs')/items",
    clientId: "75368a6b-0f57-4d7c-8ee8-2413de9098eb",
    clientSecret: "fqe7mnE7omTsfybJT11eIEOLysRC+P0vKIP2E0Jld3w="
  }
};
