import React from 'react';
import config from '../../config';

export default function TopNav({ onMenuClick = () => {} }) {
  return (
    <nav>
        <ul className="icons">
          {config.socialLinks.map(social => {
            const { icon, name, url } = social;
            
            return (
              <li key={url} style={{marginRight:'.25em'}}>
                <a href={url} className={`icon style2 ${icon}`} 
                  style={{border:'solid 1px #c9c9c9', width:'3.5em', paddingLeft:'1em'}} target="_blank" rel="noreferrer">
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
          <li >
            <a  href="#" className={'icon style2 fa-paper-plane'}
              style={{border:'solid 1px #c9c9c9', width:'3.5em', paddingLeft:'1em'}}
                onClick={e => {
                  e.preventDefault();
                  onMenuClick(true);
                }}>
                <span className='label'>Reach Us</span>
            </a>
          </li>
        </ul>
      </nav>
    
  );
}
