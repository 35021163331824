import React from 'react';
import { Link } from 'gatsby';
import {Card} from '../components/Card';

import ProcessFlowImage from '../assets/images/icon-processflow.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import TechnologyImage from '../assets/images/icon-technology.svg';

export default function Footer() {
  return (
    <footer id="footer" style={{backgroundColor:'#c7d9ec', padding:'2em 0 .25em 0'}}>
      <div className="inner row" style={{margin:'.25em auto'}}>
        <h4 style={{margin:'5px 0 1px 0'}}>Our Prime focus</h4>
        <div className="row" style={{display:'flex', flexWrap:'wrap'}}>
            <Card cardTitle="Process" cardImage={ProcessFlowImage}>
              Process excellence & continuous improvement 
              is our key to successful execution.

            </Card>
            <Card cardTitle="Talent" cardImage={TalentImage}>
              We source the right talent, from across the globe, 
              to achieve perfect agility.
            </Card>
            <Card cardTitle="Technology" cardImage={TechnologyImage}>
              We use the right tools and technology to automate 
              complex business processes.
            </Card>
            
        </div>
      </div>
      <div className="inner row" style={{display:'flex', flexDirection:'column',margin:'.25em auto'}}>
        <h4 style={{margin:'5px 0 1px 0'}}>Get to know us</h4>
        <div className="row" 
            style={{display:'flex', flexWrap:'wrap'}}>
            <Card cardTitle="Practices" >
              <li><Link to='/transform-your-business/'>Transformation</Link></li>
              <li><Link to='/business-solutions-on-the-cloud/'>Business Solutions</Link></li>
              <li><Link to='/talent-services/'>Talent Services</Link></li>

            </Card>
            
            <Card cardTitle="Discover More" >
              <li><Link to='/about-us/'>About Us</Link></li>
              <li><Link to='/career/'>Career</Link></li>
              <li><Link to='/current-jobs/'>Current Openings</Link></li>

            </Card>
            <Card cardTitle="Cloutics.Com" >
                
                <a href='/privacy-policy/'>Privacy Policy</a><br/>
                <a href='/terms-of-use/'>Terms of Use</a>
                <ul className="copyright" style={{margin:'5px', fontWeight:400}}>
                  <li>&copy; {(new Date()).getFullYear()} Cloutics Inc. All rights reserved</li>
                  
                </ul>
              
            </Card>
            
        </div>
        
      </div>
    </footer>
  );
}
