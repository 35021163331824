import React, { useState } from 'react';
import Nav from './Nav';
import TopNav from './TopNav';
import { Link } from 'gatsby';
import logo from '../assets/images/cloutics-logo-tr.png';
export default function SideBar({ sections = [] }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header" style={{backgroundColor:'#edf3f9'}}>
        <div className="inner" style={{display:"flex", justifyContent:"space-between"}}>
          <Link to="/" className="logo">
            <span className="symbol">
              <img src={logo} alt="Cloutics" />
            </span>
          </Link>
          <TopNav onMenuClick={() => { toggleHeader(!headerOpen)}} />
        </div>
        <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
          <Nav onClose={() => toggleHeader(!headerOpen)} />
        </div>
      </header>
    </>
  );
}